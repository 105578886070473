import React, {FC, useEffect, useState} from 'react';
import './App.scss';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardSubTitle,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBRow
} from "mdb-react-ui-kit";
import StartScreen from "./screen/Start/StartScreen";
import {Route, Routes} from "react-router-dom";
import LearnScreen from "./screen/Learn/LearnScreen";
import Question from "./screen/Learn/Question";
import StartQuestion from "./screen/Learn/StartQuestion";
import {auth, signInWithGoogle} from './firebase';
import {User} from "firebase/auth";

const App: FC = () => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            // @ts-ignore
            setUser(user);
        });
        return unsubscribe;
    }, []);

    const handleSignIn = () => {
        signInWithGoogle().catch((error: any) => {
            console.error(error);
        });
    };

    return (
        <MDBContainer className="App">
            {user ? (
                <>
                    <Routes>
                        <Route path="/" element={<StartScreen user={user}/>}/>
                        <Route path="/learn/:id" element={<LearnScreen/>}>
                            <Route index element={<StartQuestion/>}/>
                            <Route path="next" element={<StartQuestion/>}/>
                            <Route path="next/:not" element={<StartQuestion/>}/>
                            <Route path=":questionId" element={<Question/>}/>
                        </Route>
                    </Routes>
                </>
            ) : (
                <>
                    <MDBCard className="my-3">
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol size="12">
                                    <MDBCardTitle>Study App (Web)</MDBCardTitle>
                                    <MDBCardSubTitle>by NFS Luca Postiglione</MDBCardSubTitle>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="mb-3">
                        <MDBBtn onClick={handleSignIn}>Sign In with Google</MDBBtn>
                    </MDBCard>
                </>
            )}
        </MDBContainer>
    );
}

export default App;
