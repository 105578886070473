import React, {FC, useState} from 'react';
import {useCurrentUser, useGetQuestion, useGetTest} from "../../data/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn, MDBCard, MDBCardBody, MDBCardSubTitle, MDBCardTitle, MDBListGroup, MDBSpinner} from "mdb-react-ui-kit";
import Answer from "../../component/Answer/Answer";
import {sendAnswer} from "../../data/save";

const Question: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const [revealed, setRevealed] = useState<boolean>(false);
    const [correct, setCorrect] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [answers, setAnswers] = useState<{ answerId: string, correct: boolean }[]>([]);

    const id = params.id ?? '';
    const questionId = params.questionId ?? '';
    const question = useGetQuestion(id, questionId);
    const test = useGetTest(id);
    const user = useCurrentUser();

    const validate = () => {
        setRevealed(true);
        let validationResult = true;
        question.data.Answers.forEach((item: any) => {
            let found = false;
            answers.forEach((answer) => {
                if (item.id === answer.answerId) {
                    found = true;
                    if (!answer.correct) {
                        validationResult = false;
                    }
                }
            })
            if (!found && item.data.valid) {
                validationResult = false;
            }
        });

        setCorrect(validationResult);

        // @ts-ignore
        sendAnswer(id, questionId, user?.uid, validationResult).then(() => setValidated(true))
    }

    const onChangeAnswer = (answerId: string, correct: boolean) => {
        let newAnswers = [...answers];
        let found = false;
        newAnswers.forEach((item) => {
            if (item.answerId === answerId) {
                found = true;
                item.correct = correct;
            }
        })
        if (!found) {
            newAnswers.push({answerId, correct})
        }
        setAnswers(newAnswers);
    }

    if (question.loading || test.loading) {
        return <div className="text-center"><MDBSpinner/></div>
    }

    return <>
        <MDBCard className="mb-5 overflow-hidden">
            <MDBCardBody>
                <MDBCardTitle
                    className={revealed ? correct ? 'text-success' : 'text-danger' : undefined}>{question.data.text}</MDBCardTitle>
                <MDBCardSubTitle>
                    <span
                        className="text-muted">Frage #{question.data.id} ({test.data.Stats.currentProgress + 2}/{test.data.amount})</span>
                </MDBCardSubTitle>
            </MDBCardBody>

            <MDBListGroup flush>
                {question.data.Answers.map((answer: any) => {
                        return <Answer
                            key={answer.id}
                            revealed={revealed}
                            answer={answer}
                            onChange={onChangeAnswer}
                        />
                    }
                )}
            </MDBListGroup>
        </MDBCard>
        {revealed ?
            <MDBBtn block color="primary" size="lg"
                    onClick={() => navigate('/learn/' + params.id + '/next')}
                    disabled={!validated}>Weiter</MDBBtn>
            :
            <MDBBtn block color="success" size="lg" onClick={validate}>Prüfen</MDBBtn>
        }
    </>;
}

export default Question;
