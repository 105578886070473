import React, {FC, useEffect} from 'react';
import {useGetTest} from "../../data/hooks";
import firebase from "firebase/compat/app";
import {random} from "../../data/random";
import {useNavigate, useParams} from "react-router-dom";
import {MDBSpinner} from "mdb-react-ui-kit";

const StartQuestion: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const test = useGetTest(params.id ?? '');

    useEffect(() => {
        if (test.data) {
            const db = firebase.firestore();
            let num = random(1, test.data.amount, parseInt(params.not ?? '0'));
            if (typeof test.data.Stats.questionOrder !== "undefined" && typeof test.data.Stats.currentProgress !== "undefined") {
                num = test.data.Stats.questionOrder[test.data.Stats.currentProgress + 1];
            }
            db.collection('Tests/' + params.id + '/Questions')
                .where("id", ">=", num)
                .limit(1)
                .get()
                .then((querySnapshot) => {
                    let item = querySnapshot.docs[0];
                    navigate('/learn/' + test.id + '/' + item.id);
                })
        }
    }, [navigate, params.id, params.not, test.data, test.id]);

    return <div className="text-center">
        <MDBSpinner/>
    </div>;
}

export default StartQuestion;
