import React, {FC} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBIcon,
    MDBProgress,
    MDBProgressBar,
    MDBSpinner
} from "mdb-react-ui-kit";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useGetTest} from "../../data/hooks";

const LearnScreen: FC = () => {
    const navigate = useNavigate();
    const params = useParams();

    const id = params.id ?? '';

    let test = useGetTest(id);

    return <>
        <div className="wrapper">
            <MDBCard className="mt-2 mb-4 overflow-hidden">
                {test.loading && <div className="my-5 text-center"><MDBSpinner/></div>}
                {!test.loading && <>
                    <MDBCardBody className="pb-3">
                        <MDBCardTitle className="m-0 p-0">{test.data?.name}</MDBCardTitle>
                    </MDBCardBody>
                    <MDBProgress height={2}>
                        <MDBProgressBar bgColor="warning"
                                        width={(test.data?.Stats.currentProgress / test.data?.amount * 100) ?? 1}
                                        valuemin={0}
                                        valuemax={100}>
                        </MDBProgressBar>
                    </MDBProgress>
                    <MDBProgress height='25'>
                        <MDBProgressBar striped animated bgColor="info"
                                        width={(test.data?.Stats.correctPercent - test.data?.Stats.learnedPercent) ?? 0}
                                        valuemin={0}
                                        valuemax={100}>
                            {(Math.round(test.data?.Stats.correctPercent * 10) / 10) ?? 0}%
                        </MDBProgressBar>
                        <MDBProgressBar striped animated bgColor="success"
                                        width={test.data?.Stats.learnedPercent ?? 0}
                                        valuemin={0}
                                        valuemax={100}>
                            {(Math.round(test.data?.Stats.learnedPercent * 10) / 10) ?? 0}%
                        </MDBProgressBar>
                    </MDBProgress>
                </>}
            </MDBCard>
            <Outlet/>
            <MDBBtn block onClick={() => navigate('/')} color="link" className="mt-4 mb-2" size="lg">
                <MDBIcon fas icon={"angle-left"} size="2"/>
                &nbsp;Beenden
            </MDBBtn>
        </div>
    </>;
}

export default LearnScreen;
