import {MDBBtn, MDBCardBody, MDBCardSubTitle, MDBCardTitle, MDBCol, MDBRow} from "mdb-react-ui-kit";
import React, {FC, PropsWithChildren} from "react";
import {User} from "firebase/auth";
import {signOut} from "../../firebase";

type AnswerProps = { user: User };
const Header: FC<PropsWithChildren<AnswerProps>> = ({user}) => {
    const handleSignOut = () => {
        signOut().catch((error: any) => {
            console.error(error);
        });
    };

    return <MDBCardBody>
        <MDBRow>
            <MDBCol size="6">
                <MDBCardTitle>Study App (Web)</MDBCardTitle>
                <MDBCardSubTitle>by NFS Luca Postiglione</MDBCardSubTitle>
            </MDBCol>
            <MDBCol size="6" className="text-end">
                <p className="mb-2">Hallo {user.displayName}!</p>
                <MDBBtn onClick={handleSignOut}>Ausloggen</MDBBtn>
            </MDBCol>
        </MDBRow>
    </MDBCardBody>
}

export default Header;
