import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firebase from "firebase/compat/app";

const firebaseConfig = {
    apiKey: "AIzaSyBIHnzw0Ap5HP6p5eB-_zkquMgsJjOSmsE",
    authDomain: "lerninator.firebaseapp.com",
    projectId: "lerninator",
    storageBucket: "lerninator.appspot.com",
    messagingSenderId: "638879844028",
    appId: "1:638879844028:web:ce127c29038e948768f2b9"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
export const auth = firebase.auth();

export const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider);
}

export const signOut = () => {
    return auth.signOut();
}

export const signInWithEmail = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
}

export const signUpWithEmail = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export const signInWithPhoneNumber = (phoneNumber, appVerifier) => {
    return firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
}
