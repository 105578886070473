import React, {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {User} from 'firebase/auth';
import {auth} from "../firebase";

export const useCurrentUser = () => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            // @ts-ignore
            setUser(user);
        });
        return unsubscribe;
    }, []);

    return user;
};
export const useGetTests = () => {
    const [documents, setDocuments] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const db = firebase.firestore();
    const user = useCurrentUser();

    useEffect(() => {
        db.collection("Tests")
            .orderBy("name", "asc")
            .get()
            .then(async (querySnapshot) => {
                let arr: any[] = [];
                let statsData: any = {learnedPercent: 0, correctPercent: 0};
                for (const doc of querySnapshot.docs) {
                    let stats = await doc.ref.collection('UserStats')
                        .where('userId', '==', user?.uid ?? 'single_user')
                        .limit(1)
                        .get()
                    if (stats.docs.length) {
                        statsData = stats.docs[0].data();
                    } else {
                        statsData = {learnedPercent: 0, correctPercent: 0};
                    }
                    arr.push({id: doc.id, ref: doc.ref, data: {...doc.data(), Stats: statsData}});
                }
                setDocuments(arr);
                setLoading(false)
            });
    }, [db, user?.uid]);
    return {documents, loading};
};

type documentState = { data?: any, id?: string, ref?: any, }

type getDocumentResponse = documentState & { loading: boolean }

export const useGetDocument = (collection: string, documentPath: string): getDocumentResponse => {
    const [document, setDocument] = React.useState<documentState>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const db = firebase.firestore();

    useEffect(() => {
        db.collection(collection)
            .doc(documentPath)
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.exists) {
                    setDocument({data: querySnapshot.data(), id: querySnapshot.id, ref: querySnapshot.ref});
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }, [collection, db, documentPath]);
    return {...document, loading};
};

export const useGetTest = (testId: string): getDocumentResponse => {
    const [document, setDocument] = React.useState<documentState>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const db = firebase.firestore();
    const user = useCurrentUser();

    useEffect(() => {
        db.collection('Tests')
            .doc(testId)
            .get()
            .then((item) => {
                if (item.exists && user?.uid) {
                    console.log(user?.uid);
                    let data = item.data() ?? {};
                    item.ref.collection('UserStats')
                        .where('userId', '==', user?.uid)
                        .onSnapshot((snap) => {
                            if (snap.empty) {
                                data.Stats = {learnedPercent: 0, correctPercent: 0};
                            } else {
                                data.Stats = snap.docs[0].data();
                            }
                            setDocument({data: data, id: item.id, ref: item.ref});
                            setLoading(false)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }, [db, testId, user]);
    return {...document, loading};
};

function shuffle(array: any[]) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const useGetQuestion = (testId: string, questionId: string): getDocumentResponse => {
    const [document, setDocument] = React.useState<documentState>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const db = firebase.firestore();

    useEffect(() => {
        db.collection('Tests/' + testId + '/Questions')
            .doc(questionId)
            .get()
            .then((item) => {
                if (item.exists) {
                    let data = item.data() ?? {};
                    let answers: any[] = [];
                    item.ref.collection('Answers').get().then((snap) => {
                        snap.forEach((answer) => {
                            answers.push({data: answer.data(), id: answer.id, ref: answer.ref});
                        })
                        data.Answers = shuffle(answers);
                        setDocument({data: data, id: item.id, ref: item.ref});
                        setLoading(false)
                    })
                }
            })
            .catch((error) => console.log);
    }, [db]);
    return {...document, loading};
};
