import React, {FC, PropsWithChildren, useState} from "react";
import {MDBCheckbox, MDBListGroupItem} from "mdb-react-ui-kit";

type AnswerProps = { answer: any, revealed?: boolean, onChange?(answerId: string, correct: boolean): void };

const Answer: FC<PropsWithChildren<AnswerProps>> = ({answer, revealed, onChange}) => {
    const [selected, setSelected] = useState<boolean>(false)

    if (typeof revealed === 'undefined') {
        revealed = false;
    }
    const onClick = () => {
        if (onChange) {
            onChange(answer.id, answer.data.valid === !selected);
        }
        setSelected(!selected)
    }
    return <MDBListGroupItem
        action
        className='py-3 d-flex justify-content-start align-items-center'
        role={revealed ? undefined : 'button'}
        key={answer.id}
        onClick={revealed ? undefined : () => onClick()}
        color={revealed ? answer.data.valid ? 'success' : 'danger' : undefined}
    >
        <MDBCheckbox btnColor="success" checked={selected} disabled={revealed} onChange={() => {
        }}/>
        {answer.data.text}
    </MDBListGroupItem>
}

export default Answer;
