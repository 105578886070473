import React, {FC} from "react";
import {MDBBadge, MDBCard, MDBIcon, MDBListGroup, MDBListGroupItem, MDBSpinner} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";
import {useGetTests} from "../../data/hooks";
import {User} from "firebase/auth";
import Header from "../../component/Header/Header";

const StartScreen: FC<{ user: User }> = ({user}) => {
    const navigate = useNavigate();
    const tests = useGetTests();

    return <>
        <MDBCard className="my-3">
            <Header user={user}/>
        </MDBCard>
        <MDBCard className="mb-3">
            {tests.loading && <div className="my-5 text-center"><MDBSpinner/></div>}
            <MDBListGroup flush>
                {tests.documents.map((test) => <MDBListGroupItem
                        action
                        className='py-3 d-flex justify-content-between align-items-center'
                        onClick={() => navigate(`/learn/${test.id}`)}
                        role="button"
                        key={test.id}
                    >
                        {test.data.name}
                        <div className="text-nowrap">
                            <MDBBadge pill color="info" className="mr-2">
                                {Math.round(test.data.Stats.correctPercent)}%
                            </MDBBadge>
                            &nbsp;
                            <MDBBadge pill color="success" className="mr-2">
                                {Math.round(test.data.Stats.learnedPercent)}%
                            </MDBBadge>
                            &nbsp;
                            <MDBBadge pill><MDBIcon icon="angle-right"/></MDBBadge>
                        </div>
                    </MDBListGroupItem>
                )}
            </MDBListGroup>
        </MDBCard>
    </>;
}

export default StartScreen;
