import firebase from "firebase/compat/app";

export const shuffleQuestions = (amount: number) => {
    let a = [];
    for (let i = 1; i <= amount; i++) {
        a.push(i);
    }

    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }

    return a;
}

export const sendAnswer = async (testId: string, questionId: string, userId: string, correct: boolean) => {
    const db = firebase.firestore();

    const testSnap = await db.collection("Tests")
        .doc(testId)
        .get();

    let testData;
    if (!testSnap.exists) {
        throw "Test does not exist";
    } else {
        testData = testSnap.data() ?? {};
    }

    const currentTStatsQuery = await testSnap.ref
        .collection('UserStats')
        .where('userId', '==', userId)
        .get();

    const questionSnap = await testSnap.ref
        .collection("Questions")
        .doc(questionId);

    const currentQStatsQuery = await questionSnap
        .collection('UserStats')
        .where('userId', '==', userId)
        .get();

    let tStats: any = {};
    let oldTStatsSnap = null;
    if (!currentTStatsQuery.empty) {
        oldTStatsSnap = currentTStatsQuery.docs[0];
        const oldTStats = oldTStatsSnap.data();
        tStats = {...oldTStats};
    } else {
        tStats = {
            userId: userId,
            correct: 0,
            correctPercent: 0,
            learned: 0,
            learnedPercent: 0,
            currentProgress: -1,
            questionOrder: shuffleQuestions(testData.amount)
        }
    }

    let stats: any = {};
    let oldStatsSnap = null;
    if (currentQStatsQuery.empty) {
        stats = {
            userId: userId,
            answered: 1,
            correct: correct ? 1 : 0,
            incorrect: correct ? 0 : 1,
            streak: correct ? 1 : 0,
            learned: false,
        };
        if (correct) {
            tStats.correct++;
            tStats.correctPercent = (tStats.correct / testData.amount) * 100;
        }
    } else {
        oldStatsSnap = currentQStatsQuery.docs[0];
        const oldStats = oldStatsSnap.data();

        stats = {...oldStats}
        stats.answered++;
        if (correct) {
            stats.correct++;
            stats.streak++;
        } else {
            stats.incorrect++;
            stats.streak = 0;
        }

        stats.learned = stats.streak >= 3;

        if (oldTStatsSnap !== null) {
            if (oldStats.streak >= 3 && stats.streak === 0) {
                tStats.learned--;
            } else if (oldStats.streak < 3 && stats.streak === 3) {
                tStats.learned++;
            }
            tStats.learnedPercent = (tStats.learned / testData.amount) * 100;

            if (correct && oldStats.streak === 0) {
                tStats.correct++;
            } else if (!correct && oldStats.streak > 0) {
                tStats.correct--;
            }
            tStats.correctPercent = (tStats.correct / testData.amount) * 100;
        }
    }

    if (typeof tStats.currentProgress === "number" && (testData.amount - 2) > tStats.currentProgress) {
        tStats.currentProgress++;
    } else {
        tStats.currentProgress = -1;
    }

    if (tStats.currentProgress === -1) {
        tStats.questionOrder = shuffleQuestions(testData.amount);
    }

    if (oldTStatsSnap) {
        await oldTStatsSnap.ref.update(tStats);
    } else {
        await testSnap.ref.collection('UserStats').add(tStats);
    }

    if (oldStatsSnap) {
        return await oldStatsSnap.ref.update(stats);
    } else {
        return await questionSnap.collection('UserStats').add(stats);
    }
}
